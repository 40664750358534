import axios from 'axios'
import router from '@/router';
import { MessageBox, Loading,Message } from 'element-ui';
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
import store from '@/store/index'
axios.defaults.baseURL = 'https://website.heiwaimao.com/admin'
// axios.defaults.baseURL = 'http://192.168.31.168:7878/admin'
// axios.defaults.baseURL = 'http://192.168.1.5:7878/admin'
// http://192.168.1.26:7878
//http://39.99.145.75:7878
//http://39.98.217.62:7878
//https://heifang.xajudao.com/admin

let loading, debounceTimer;
let loadingNum = 0;

let isShowtitle = true

function startLoading() {
  if (loadingNum == 0) {
    loading = Loading.service({
      lock: true,
      text: '加载中...',
      background: 'rgba(255,255,255,0.5)',
      target: document.querySelector('.content')
    })
  }
  loadingNum++;
}

function endLoading() {  //  关闭加载动画
  loadingNum--;
  if (loadingNum <= 0) {
    loading.close();
    loadingNum == 0
  }
}



axios.interceptors.request.use(config => {
  startLoading()
  config.headers.token = localStorage.getItem('Authentication')
  //isAntiShake
  if (config.method == 'post') {
    return new Promise(resolve => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        // 在防抖时间后发起实际请求
        // axios.request(config);
        resolve(config);
      }, 200);
    })

  } else {
    return config;
  }
  // if (isAntiShake) {

  // } else {

  // }


  // return config
}, (error) => {
  endLoading()
  return Promise.reject(error)
})
axios.interceptors.response.use(({ data }) => {
  loadingNum = 0
  endLoading()
  switch (data.code) {
    case 401:
      
        store.commit('homePage/setisCompons',[true,false, false, true, false, false, true, true])
        store.commit('homePage/setloginstatus',false)
        store.commit('homePage/setloginuserinfo',{})
        store.commit('homePage/setUserInfo',{})
      
      if(isShowtitle){
        isShowtitle = false
        Message.error(data.msg);
        setTimeout(()=>{
          isShowtitle = true
        },1000)
      }
      

      // router.push({
      //   path: '/'
      // })
      const currentRoute = router.currentRoute;

      // 检查是否与即将导航的路由相同
      if (currentRoute.path !== '/'&&currentRoute.path !== '/cardef') {
        // 执行编程式导航
        router.push('/');
      }
      break;
  }
  return data
}, error => {
  endLoading()
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        MessageBox({
          message: '未授权，请重新登录',
          type: 'error',
          title: '提示'
        })

        store.commit('homePage/setisCompons',[true,false, false, true, false, false, true, true])
        store.commit('homePage/setloginstatus',false)
        store.commit('homePage/setloginuserinfo',{})
        store.commit('homePage/setUserInfo',{})

        break;
      case 404:
        MessageBox({
          message: '网络请求不存在',
          type: 'error',
          title: '提示'
        });
        break;
      // 其他错误，直接抛出错误提示
      default:
        MessageBox({
          message: '服务器内部错误，请稍后再试',
          type: 'error',
          title: '提示'
        });
    }
    return Promise.reject('error');
  }

})
